import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '../../../context/AppContext';
import { useMediaQuery } from 'react-responsive';
import { mixpanelTrack } from '../../../mixpanel';

interface IPropsFooter {
  showFooter: boolean;
  isIntro: boolean;
}

export const Footer: FC<IPropsFooter> = ({ showFooter, isIntro }) => {
  const appContext = useAppContext() || undefined;
  const { t } = useTranslation('components/modal');

  const isMobile = useMediaQuery({ maxWidth: 1000 });

  const track = () => {
    mixpanelTrack('ACTION_Click_OpenPP');
  };

  return (
    <Box
      sx={{
        height: 'fit-content',
        width: '100%',
        display: showFooter ? 'none' : 'flex',
        alignItems: 'end',
        justifyContent: 'right',
        marginBottom: '5px',
        textTransform: 'lowercase'
      }}
    >
      <Box
        sx={{
          width: isMobile || !isIntro ? '100%' : '55%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {isIntro && (
          <Box
            sx={{
              display: 'flex',
              textTransform: 'lowercase'
            }}
          >
            <Typography
              color={
                isMobile && isIntro
                  ? 'white'
                  : isIntro
                    ? appContext?.style?.intro
                    : 'black'
              }
              fontFamily={appContext?.style?.font ?? 'Helvetica'}
              fontSize="10px"
              fontWeight={300}
              sx={{
                opacity: 0.7,
                textAlign: 'center',
                cursor: 'default',
                marginRight: '3px',
                zIndex: 10
              }}
            >
              {t('privacyPolicy_intro')}
            </Typography>
            <a
              target="_blank"
              href="https://www.kleep.ai/legal"
              onClick={track}
              style={{
                opacity: 0.7,
                color:
                  isIntro && isMobile
                    ? 'white'
                    : isIntro
                      ? appContext?.style?.intro
                      : 'black',
                fontFamily: appContext?.style?.font ?? 'Helvetica',
                fontSize: '10px',
                fontWeight: 300,
                textDecoration: 'underline',
                marginBottom: '3px',
                marginRight: '4px',
                zIndex: 10
              }}
              rel="noreferrer"
            >
              {t('privacyPolicy_link')}
            </a>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Typography
            color={
              isMobile && isIntro
                ? 'white'
                : isIntro
                  ? appContext?.style?.intro
                  : 'black'
            }
            fontFamily={appContext?.style?.font ?? 'Helvetica'}
            fontSize="10px"
            fontWeight={300}
            sx={{
              opacity: 0.7,
              textAlign: 'center',
              marginBottom: '3px',
              cursor: 'default',
              zIndex: 10
            }}
          >
            {t('poweredBy')}
          </Typography>
          <img
            style={{
              width: '32px',
              marginLeft: '3px',
              marginBottom: '4px',
              height: 'auto',
              // Cette propriété change la couleur du logo
              // On l'affiche en blanc, quand on est sur petit format ou sur une page Intro avec un backgroud de couleur foncé
              filter:
                isMobile && isIntro
                  ? 'invert(1)'
                  : isIntro && appContext?.style?.intro == 'white'
                    ? 'invert(1)'
                    : undefined,
              zIndex: 10
            }}
            src={require('../../../assets/logo/kleep.png')}
          />
        </Box>
      </Box>
    </Box>
  );
};
