import React, { FC, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getLocalAndParse } from '../../../../store/localStoreUtils';
import { useAppContext } from '../../../../context/AppContext';
import { useUserContext } from '../../../../store/userContext';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import './stepIntro.scss';
import { mixpanelTrack } from '../../../../mixpanel';

const getTextTransform = (
  caseValue?: string
): 'none' | 'capitalize' | 'uppercase' | 'lowercase' => {
  return ['none', 'capitalize', 'uppercase', 'lowercase'].includes(
    caseValue || ''
  )
    ? (caseValue as 'none' | 'capitalize' | 'uppercase' | 'lowercase')
    : 'none';
};

interface IPropsStepIntroDesktop {
  nextStep: () => void;
  setLocalGender: (value: string) => void;
  uniqueGender: string | null;
}

const StepIntroDesktop: FC<IPropsStepIntroDesktop> = ({
  nextStep,
  setLocalGender,
  uniqueGender
}) => {
  const appContext = useAppContext() || undefined;
  const { t } = useTranslation('components/intro');
  const { setUser } = useUserContext() || {};
  const userStored = getLocalAndParse('user');
  const [selectGender, setSelectGender] = useState<string | undefined>(
    userStored?.gender
  );

  useEffect(() => {
    if (uniqueGender) {
      saveGender(uniqueGender);
      setSelectGender(uniqueGender);
      setLocalGender(uniqueGender);
    }
  }, [appContext, uniqueGender]);

  useEffect(() => {
    if (selectGender) saveGender(selectGender);
  }, [selectGender]);

  const saveGender = (gender?: string) => {
    if (gender) {
      const newUser = {
        gender: gender
      };

      localStorage.setItem('user', JSON.stringify(newUser));
      setLocalGender(gender);

      if (setUser) {
        setUser(newUser);
      }
    }
  };

  const start = () => {
    selectGender !== undefined && nextStep();
  };

  return (
    <div className="intro-desktop">
      <div className="intro-desktop__block" />
      <div className="intro-desktop__container">
        <h1 className="intro-desktop__container__title">
          <Trans
            ns="components/intro"
            i18nKey="desktop.title"
            values={{ separator: '\n' }}
          />
        </h1>
        {!uniqueGender && (
          <>
            <p className="intro-desktop__container__description">
              {t('desktop.description')}
            </p>
            <div className="intro-desktop__container__buttons">
              <button
                className={`intro-desktop__container__buttons__button${
                  useAppContext()?.style.intro == 'white' ? '__light' : '__dark'
                }${selectGender === 'male' ? '--active' : ''}`}
                onClick={() => {
                  mixpanelTrack('ACTION_Click_Gender_Select', {
                    value: 'male'
                  });
                  setLocalGender('male');
                  setSelectGender('male');
                }}
              >
                {t('gender.male')}
              </button>
              <button
                className={`intro-desktop__container__buttons__button${
                  useAppContext()?.style.intro == 'white' ? '__light' : '__dark'
                }${selectGender === 'female' ? '--active' : ''}`}
                onClick={() => {
                  mixpanelTrack('ACTION_Click_Gender_Select', {
                    value: 'female'
                  });
                  setLocalGender('female');
                  setSelectGender('female');
                }}
              >
                {t('gender.female')}
              </button>
            </div>
          </>
        )}
        <button
          className={
            useAppContext()?.style.intro == 'white'
              ? 'intro-desktop__container__buttons__continue__light'
              : 'intro-desktop__container__buttons__continue__dark'
          }
          disabled={!uniqueGender && selectGender === undefined}
          onClick={() => {
            saveGender(selectGender || '');
            start();
            mixpanelTrack('ACTION_Click_Gender_Confirm', {
              value: selectGender
            });
          }}
          style={{
            textTransform: getTextTransform(appContext?.style?.cta?.case)
          }}
        >
          <div className="intro-desktop__container__buttons__continue__label">
            {t('start')}
          </div>
          <ArrowForwardIcon />
        </button>
      </div>
    </div>
  );
};

export default StepIntroDesktop;
